import React, { useState, useEffect, useRef } from 'react'
import api from './api'


const App = () => {
  const [products, setProducts] = useState([]);
  const fileInputRef = useRef(null);
  const [formData, setFormData] = useState({
    price: '',
    category: '',
    description: '',
    is_instock: false,
    date: '',
  });
  // const [userImageData, setUserImageData] = useState({
  //   image_data: [],
  //   image_type: '',
  // })

  // 
  // const fetchProducts = async () => {
  //   const response = await api.get('/products/');
  //   const data = await response.data;
  //   setProducts(data);
  // };

  const fetchProducts = () => {
    api.get('/products/')
      .then(response => {
        const data = response.data;
        setProducts(data);
      })
      .catch(error => {
        console.error('Failed to fetch products:', error);
        // Optionally, handle the error further, e.g., display a message to the user
      });
  };
  

  useEffect(() => {
    fetchProducts();
  }, []);

  const handelInputChange = (event) => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    setFormData({
      ...formData,
      [event.target.name]: value,
    });
  };


  const handelFormSubmit = async (event) => {
    event.preventDefault();
    await api.post('/products/', formData);
    fetchProducts();
    setFormData({
      price: '',
      category: '',
      description: '',
      is_instock: false,
      date: ''
    });
  };

  const handelImageSubmit = async (event) => {
    const element = document.querySelector("input[name='user_image']");
    const img_data = JSON.parse(element.value)
    const userInputImage = {
      image_name: img_data.name,
      image_type: img_data.type,
      image_data: img_data.data,
      search_type: ""
    };
    event.preventDefault();
    setProducts([]);
    await api.post('/user_image/', userInputImage).then(
      response => {
        setProducts(response.data.products);
        console.log('product',products);
      }).catch(error => {
        console.error('Error submitting image:', error);
      });
  };

  return (
    <div>
      <nav className='navbar navbar-dark bg-primary'>
        <div className='container-fluid'>
          <a className='navbar-brand' href='#'>
            Qualya App
          </a>
        </div>
      </nav>
      <div className="container mt-5">
        <header className="text-center mb-4">
          <h1>Upload or Drag and Drop an Image</h1>
          <p>Enhance your search experience by uploading an image directly.</p>
        </header>


        <div id="drop-area" className="mb-3 p-5 border border-dashed">
          <p className="text-center">Drag and drop an image here, or click to select a file.</p>
          <input type='file' name="user_image" ref={fileInputRef} className='filepond'></input>
        </div>

        <form onSubmit={handelImageSubmit} className="input-group mb-5">
          <select className="form-select" name="search_type">
            <option value="simple_search">Simple Search</option>
            <option value="advanced_search">Advanced Search</option>
          </select>
          <button type="submit" id="upload-button" className="btn btn-primary">Search</button>
        </form>

        <div className='container'>
          <form onSubmit={handelFormSubmit}>
            <div className='mb-3 mt-3'>
              <label htmlFor='price' className='form-label'>
                Price
              </label>
              <input type='text' className='form-control' id='price' name='price' onChange={handelInputChange} value={formData.price}></input>
            </div>

            <div className='mb-3 mt-3'>
              <label htmlFor='category' className='form-label'>
                Product category
              </label>
              <input type='text' className='form-control' id='category' name='category' onChange={handelInputChange} value={formData.category}></input>
            </div>

            <div className='mb-3 mt-3'>
              <label htmlFor='description' className='form-label'>
                Product description
              </label>
              <input type='text' className='form-control' id='description' name='description' onChange={handelInputChange} value={formData.description}></input>
            </div>

            <div className='mb-3 mt-3'>
              <label htmlFor='is_instock' className='form-label'>
                Instock?
              </label>
              <input type='checkbox' id='is_instock' name='is_instock' onChange={handelInputChange} value={formData.is_instock}></input>
            </div>
          </form>
          <div class="container mt-5">
            <a href="/" class="btn btn-primary mb-3">Return to Home</a>
            <h1>Search Results for Query</h1>
            <div class="card h-100">
              {/* <img src="{{ query.image }}" class="card-img-top"> */}
              <div class="card-body">

              </div>
            </div>
            <div class="row row-cols-1 row-cols-md-3 g-4">
              {products.map((product) => (
                <div class="col">
                <div class="card h-100">
                  <img src={product.product_image_url_list.main[0]} class="card-img-top" alt="{{ product.name }}"></img>
                    <div class="card-body">
                      <h5 class="card-title">{product.name}</h5>
                      <p class="card-text">${product.brand_id}</p>
                      <p class="card-text">${product.price.price_original}</p>
                    </div>
                </div>
              </div>
              ))}
                {/* <tr key={product.id}>
                  <td>{product.price.price_original}</td>
                  <td>{product.category}</td>
                  <td>{product.description}</td>
                  <td>{product.is_instock ? 'Yes' : 'No'}</td>
                  <td>{product.date}</td>
                </tr> */}
              {/* ))} */}
            </div>
          </div>
          <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js"></script>
        </div>

        <footer className="text-center text-muted">
          <p>App Version: 1.0.0 | Developed by Qualya</p>
          <p>Additional information about the app and user terms.</p>
        </footer>
      </div>




    </div>
  )


}



export default App;
